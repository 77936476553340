import { Button, Container, Form, Image, Nav, Navbar } from "react-bootstrap";
import { Link, Outlet, useMatch, useNavigate } from "react-router-dom";

import RECIPLogoDark from "../assets/RECIP-logo-dark.svg";
import { Auth } from "aws-amplify";
import useAuthRoles from "./useAuthRoles";
import useAuth from "./useAuth";
import { useRECIPOnlineContext } from "../components/RECIPOnlineContext";
import RECIPOnlineStudyFlyerModal from "../components/RECIPOnlineFlyerModal";

import useEducationalPlatformClick from "./useEducationalPlatformClick";

function Layout(): React.ReactElement {
  const { isAdmin } = useAuthRoles();
  const { isAuthenticated } = useAuth();
  const { showFlyer, setShowFlyer } = useRECIPOnlineContext();

  const navigate = useNavigate();

  const isLandingPage = useMatch({ path: "/" }) != null;
  const onEducationalPlatformClick = useEducationalPlatformClick();

  return (
    <>
      <RECIPOnlineStudyFlyerModal
        show={showFlyer}
        onHide={() => {
          setShowFlyer(false);
        }}
      />
      <Navbar expand="lg" className="bg-off-black navbar-dark">
        <Container fluid="lg">
          <Link to="/" className="navbar-brand">
            <Image
              fluid
              style={{ height: 30, width: "auto" }}
              src={RECIPLogoDark}
              alt="RECIP Project logo"
              className="d-inline-block align-top"
            />
          </Link>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse id="navbar-nav" className="text-off-black">
            <Nav className="ms-auto me-2">
              {isAdmin && (
                <Nav.Link
                  href="https://newsroom.recip-criteria.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-off-black"
                >
                  [Admin-only] Newsroom
                </Nav.Link>
              )}
              <Link to="/technology" className="nav-link text-off-black">
                Technology
              </Link>
              <Link
                to="/science_and_impact"
                className="nav-link text-off-black"
              >
                Science &amp; Impact
              </Link>
              {!isLandingPage && (
                <Link to="/" className="nav-link text-off-black">
                  About
                </Link>
              )}
              {isAdmin && (
                <Link to="/admin" className="nav-link text-off-black">
                  Admin console
                </Link>
              )}
              {!isAuthenticated && (
                <Link to="/login" className="nav-link text-off-black">
                  Sign in
                </Link>
              )}
              {isAuthenticated && (
                <Nav.Link
                  className="ms-auto text-off-black"
                  onClick={() => {
                    navigate("/");
                    Auth.signOut({ global: true });
                  }}
                >
                  Sign out
                </Nav.Link>
              )}
            </Nav>
            <Form className="d-flex">
              <Button className="btn-blue" onClick={onEducationalPlatformClick}>
                Educational Platform
              </Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Outlet />
      <Container fluid className="bg-off-black">
        <footer className="d-flex flex-row justify-content-center py-3">
          <span className="text-center text-off-black">
            <small>
              Designed by{" "}
              <a
                href="https://siliconalbion.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Silicon Albion
              </a>{" "}
              🇬🇧
            </small>
          </span>
        </footer>
      </Container>
    </>
  );
}

export default Layout;

import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { CareerLevel, Country, Reason, Specialty } from "./enums";
import React from "react";
import { Auth } from "aws-amplify";
import * as countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

type StudyQuestionnaireProps = {
  show: boolean;
  onHide: () => void;
};

export default function StudyQuestionnaire({
  show,
  onHide,
}: StudyQuestionnaireProps): React.ReactElement {
  const [country, setCountry] = React.useState<Country>();
  const [specialty, setSpecialty] = React.useState<Specialty>();
  const [careerLevel, setCareerLevel] = React.useState<CareerLevel>();
  const [petExperience, setPetExperience] = React.useState<number>();
  const [psmaPetExperience, setPsmaPetExperience] = React.useState<number>();
  const [reason, setReason] = React.useState<Reason>();
  const [confidenceBefore, setConfidenceBefore] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);

  async function onSubmit() {
    if (
      country !== undefined &&
      specialty !== undefined &&
      careerLevel !== undefined &&
      petExperience !== undefined &&
      psmaPetExperience !== undefined &&
      reason !== undefined &&
      confidenceBefore !== undefined
    ) {
      setIsLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        "custom:country": country.toString(),
        "custom:specialty": specialty,
        "custom:career_level": careerLevel,
        "custom:pet_experience": petExperience.toString(),
        "custom:psma_pet_experience": psmaPetExperience.toString(),
        "custom:reason": reason,
        "custom:confidence_before": confidenceBefore.toString(),
      });
      setIsLoading(false);
      onHide();
    }
  }

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title className="mx-auto">Pre-training Evaluation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Before we start, please tell us a little bit about yourself. It will
          help us understand which categories of healthcare providers are
          interested in using the training platform.
        </p>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Select
              value={country || "n/a"}
              onChange={(e) => setCountry(e.target.value as Country)}
            >
              <option disabled value="n/a">
                Select country:
              </option>
              {Object.values(Country).map((c, i) => (
                <option value={c} key={i}>
                  {countries.getName(c, "en")}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Medical field</Form.Label>
            <Form.Select
              value={specialty || "n/a"}
              onChange={(e) => setSpecialty(e.target.value as Specialty)}
            >
              <option disabled value="n/a">
                Select medical field:
              </option>
              {Object.values(Specialty).map((c, i) => (
                <option value={c} key={i}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Position</Form.Label>
            <Form.Select
              value={careerLevel || "n/a"}
              onChange={(e) => setCareerLevel(e.target.value as CareerLevel)}
            >
              <option disabled value="n/a">
                Select Position:
              </option>
              {Object.values(CareerLevel).map((c, i) => (
                <option value={c} key={i}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Experience in PET imaging (years)</Form.Label>
            <Form.Control
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={petExperience || ""}
              onChange={(e) => setPetExperience(parseInt(e.target.value) || 0)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Experience in PSMA PET imaging (years)</Form.Label>
            <Form.Control
              type="text"
              inputMode="numeric"
              pattern="[0-9]*"
              value={psmaPetExperience || ""}
              onChange={(e) =>
                setPsmaPetExperience(parseInt(e.target.value) || 0)
              }
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              Confidence in assessing visually changes in PSMA-positive total
              tumor burden using PET images
            </Form.Label>
            <div className="mb-3">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <Form.Check
                  inline
                  key={i}
                  label={i}
                  type="radio"
                  name="confidenceBefore"
                  onClick={() => {
                    setConfidenceBefore(i);
                  }}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Reason for using the educational platform</Form.Label>
            <Form.Select
              value={reason || "n/a"}
              onChange={(e) => setReason(e.target.value as Reason)}
            >
              <option disabled value="n/a">
                Select reason:
              </option>
              {Object.values(Reason).map((c, i) => (
                <option value={c} key={i}>
                  {c}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button
            disabled={
              isLoading ||
              country === undefined ||
              specialty === undefined ||
              careerLevel === undefined ||
              petExperience === undefined ||
              psmaPetExperience === undefined ||
              reason === undefined ||
              confidenceBefore === undefined
            }
            variant="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {isLoading && (
              <Spinner
                as="span"
                className="me-2"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Submit
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

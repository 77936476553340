import {
  Authenticator,
  CheckboxField,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import { Link, Navigate, useLocation } from "react-router-dom";

import "./LoginPage.css";
import useAuth from "../components/useAuth";
import { useRECIPOnlineContext } from "../components/RECIPOnlineContext";
import { Auth } from "aws-amplify";
import { Col, Container, Row } from "react-bootstrap";

interface LoginPageProps {
  initialState: "signIn" | "signUp";
}

export default function LoginPage({ initialState }: LoginPageProps) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/tests";

  const { studyParticipant, consentGiven } = useRECIPOnlineContext();

  return isAuthenticated ? (
    <Navigate to={from} replace />
  ) : (
    <Container fluid className="background-dark">
      <Row
        style={{ minHeight: "90vh", marginTop: -1, marginBottom: -1 }}
        className="d-flex align-items-center"
      >
        <Col>
          <Authenticator
            initialState={initialState}
            components={{
              SignUp: {
                FormFields() {
                  const { validationErrors } = useAuthenticator();

                  return (
                    <>
                      <Authenticator.SignUp.FormFields />

                      <CheckboxField
                        errorMessage={
                          validationErrors["custom:tnc_acknowledged"] as string
                        }
                        hasError={validationErrors.hasOwnProperty(
                          "custom:tnc_acknowledged"
                        )}
                        name="custom:tnc_acknowledged"
                        value="true"
                        label={
                          <>
                            I agree with the{" "}
                            <Link
                              to="/terms_and_conditions"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="amplify-button--link"
                            >
                              Terms and Conditions
                            </Link>
                          </>
                        }
                      />
                      <CheckboxField
                        errorMessage={
                          validationErrors["custom:study_participant"] as string
                        }
                        hasError={validationErrors.hasOwnProperty(
                          "custom:study_participant"
                        )}
                        name="custom:study_participant"
                        value="true"
                        defaultChecked={studyParticipant && consentGiven}
                        label={
                          <>
                            I want to take part in the RECIPonline study.
                            <br />I have read and accepted the{" "}
                            <Link
                              to="/RECIPonline"
                              className="amplify-button--link"
                            >
                              waiver of documentation of consent
                            </Link>
                            .
                          </>
                        }
                      />
                    </>
                  );
                },
              },
            }}
            services={{
              async handleSignUp(formData: any) {
                let { username, password, attributes } = formData;

                // If study participation is checked, we should add the disclaimer as well
                if (attributes["custom:study_participant"] != null) {
                  attributes["custom:dsc_acknowledged"] = "true";
                } else {
                  attributes["custom:dsc_acknowledged"] = undefined;
                }

                return Auth.signUp({
                  username,
                  password: password,
                  attributes,
                  autoSignIn: {
                    enabled: true,
                  },
                });
              },
              async validateCustomSignUp(formData) {
                const errors: { [key: string]: string } = {};

                if (formData["custom:tnc_acknowledged"] == null) {
                  errors["custom:tnc_acknowledged"] =
                    "You must agree to the Terms and Conditions";
                }

                return errors;
              },
            }}
          />
        </Col>
      </Row>
    </Container>
  );
}

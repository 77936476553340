import { Button, Col, Form, ListGroup, Row } from "react-bootstrap";
import { redirect } from "react-router-dom";
import {
  ResponseCategory,
  ResponseCategories,
  TestResponses,
  TestPatientAssignment,
  PatientIDs,
} from "../testConfiguration";
import TestFigure from "./TestFigure";

import "./TestQuestion.css";

type TestQuestionProps = {
  testId: number;
  currentQuestion: number;
  setCurrentQuestion: Function;
  currentQuestionAnswer: ResponseCategory | undefined;
  onAnswer: Function;
  onShowResult: Function;
  currentSUVMean: number;
  setCurrentSUVMean: Function;
};

function TestQuestion({
  testId,
  currentQuestion,
  setCurrentQuestion,
  currentQuestionAnswer,
  onAnswer,
  onShowResult,
  currentSUVMean,
  setCurrentSUVMean,
}: TestQuestionProps): React.ReactElement {
  const testPatients = TestPatientAssignment.get(testId);
  if (!testPatients) {
    console.error("Test definition not found");
    redirect("/");
    return <></>;
  }
  const currentPatientIndex = testPatients[currentQuestion];
  const currentPatientId = PatientIDs[currentPatientIndex];
  const correctResponse = TestResponses.get(currentPatientId)!;

  function currentPatientImageSrc(time: "Before" | "After"): string {
    const paddedPatientId = currentPatientId.toString().padStart(3, "0");
    return `${process.env.PUBLIC_URL}/img/Patient${paddedPatientId}-${time}-${currentSUVMean}.PNG`;
  }

  function categoryExplaination(category: ResponseCategory): string {
    switch (category) {
      case "Complete Response":
        return "Disappearance of all tumor lesions";
      case "Partial Response":
        return "≥30% reduction in PSMA-VOL";
      case "Progressive Disease":
        return "≥20% increase in PSMA-VOL";
      case "Stable Disease":
        return "<30% reduction or <20 increase in PSMA-VOL";
      default:
        return "";
    }
  }

  return (
    <>
      <Row className="d-flex justify-content-bottom">
        {/* Header */}
        <Col sm="3" className="mb-2 mt-auto">
          <h5 className="text-center text-sm-start text-white">
            Test {testId}
          </h5>
        </Col>
        <Col sm="6" className="my-2">
          <h4 className="text-center text-white">
            Case {currentQuestion + 1} of {testPatients.length}
          </h4>
        </Col>
      </Row>
      {/* Figures and SUVMean legend */}
      <div className="mx-auto" style={{ maxWidth: 768 }}>
        <Row
          className="d-flex bg-white"
          style={{
            borderRadius: "0.5rem",
            boxShadow:
              "20px 0px 4px -10px rgba(0, 0, 0, 0.20), 40px 0px 4px -20px rgba(0, 0, 0, 0.15), 62px 0px 4px -35px rgba(0, 0, 0, 0.10)",
          }}
        >
          <Col xs="1" className="d-flex flex-column">
            <p className="text-center mx-auto mt-5 mb-0">{currentSUVMean}</p>
            <div
              className="mx-auto my-1 rounded-2 d-flex align-items-center flex-fill"
              style={{
                background: "linear-gradient(black, white)",
                width: "1.5rem",
              }}
            >
              <div
                className="text-white mx-auto text-center"
                style={{
                  marginTop: "50%",
                }}
              >
                <p className="m-0">{currentSUVMean / 2}</p>
              </div>
            </div>
            <p className="text-center mx-auto mb-5">0</p>
          </Col>
          <Col xs="5" className="d-flex justify-content-center">
            <TestFigure
              src={currentPatientImageSrc("Before")}
              caption="Baseline"
              radiotracer={correctResponse.radiotracer}
            />
          </Col>
          <Col xs="5" className="d-flex justify-content-center">
            <TestFigure
              src={currentPatientImageSrc("After")}
              caption="Follow-up"
              radiotracer={correctResponse.radiotracer}
            />
          </Col>
        </Row>
        {/* SUVMean slider */}
        <Row className="d-flex my-3 align-items-center text-white">
          <Col lg="3" md="4" sm="5" className="mb-sm-2 mb-md-0 text-center">
            PET Window intensity
          </Col>
          <Col lg="9" md="8" sm="7">
            <Row className="m-0 d-flex align-items-center">
              <Col xs="1" className="text-end">
                5
              </Col>
              <Col xs="10" className="d-flex justify-content-center">
                <Form.Range
                  className="mx-sm-0 mx-xs-5"
                  value={currentSUVMean}
                  min={5}
                  max={20}
                  step={5}
                  onChange={(e) => {
                    setCurrentSUVMean(parseInt(e.target.value));
                  }}
                />
              </Col>
              <Col xs="1" className="text-start">
                20
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      {/* Answers */}
      <Row className="mt-2">
        <Col>
          <p className="mx-1 text-center text-white">
            Please categorize changes in PSMA-positive total tumor volume
            (PSMA-VOL) by RECIP criteria:
          </p>
        </Col>
      </Row>

      <Row>
        <Col className="px-0">
          <ListGroup
            className="mt-1"
            style={{
              paddingBottom: currentQuestionAnswer === undefined ? 104 : 4,
            }}
          >
            {ResponseCategories.map((category: ResponseCategory) => {
              let variant = undefined;
              if (currentQuestionAnswer !== undefined) {
                if (category === correctResponse.category) {
                  variant = "success";
                } else if (category === currentQuestionAnswer) {
                  variant = "danger";
                }
              }
              return (
                <ListGroup.Item
                  key={`Answers-${category.replace(" ", "-")}`}
                  style={{
                    pointerEvents:
                      currentQuestionAnswer === undefined ? "all" : "none",
                  }}
                  action
                  onClick={() => {
                    onAnswer(category);
                  }}
                  variant={variant}
                >
                  <b>{category}</b> ({categoryExplaination(category)})
                  {category === currentQuestionAnswer && (
                    <div className="mt-1">
                      Quantitative changes in PSMA-VOL calculated using
                      semi-automatic tumor segmentation software are{" "}
                      <b>
                        {correctResponse.followupPSMAVolume -
                          correctResponse.baselinePSMAVolume >
                        0
                          ? "+"
                          : ""}
                        {Math.round(
                          ((correctResponse.followupPSMAVolume -
                            correctResponse.baselinePSMAVolume) /
                            correctResponse.baselinePSMAVolume) *
                            100
                        )}
                        %
                      </b>
                      . This corresponds to the{" "}
                      <b>{correctResponse.category}</b> category.
                    </div>
                  )}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
      </Row>

      {/* Next step button */}
      {currentQuestionAnswer !== undefined && (
        <Row className="pb-2">
          <Col className="px-0">
            {currentQuestion >= testPatients.length - 1 ? (
              <Button
                className="w-100 btn-outline-blue"
                onClick={() => {
                  onShowResult();
                }}
              >
                Show result
              </Button>
            ) : (
              <Button
                className="w-100 btn-outline-blue"
                onClick={() => {
                  setCurrentQuestion(currentQuestion + 1);
                  setCurrentSUVMean(10);
                }}
              >
                Next case
              </Button>
            )}
          </Col>
        </Row>
      )}
    </>
  );
}

export default TestQuestion;

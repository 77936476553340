import { Auth } from "aws-amplify";
import React from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { NumberOfTests } from "../testConfiguration";

type PostStudyQuestionnaireProps = {
  testId: number;
  onSubmit: () => void;
};

export default function PostStudyQuestionnaire({
  testId,
  onSubmit,
}: PostStudyQuestionnaireProps): React.ReactElement {
  const [confidenceAfter, setConfidenceAfter] = React.useState<number>();
  const [favouritePetIntensity, setFavouritePetIntensity] =
    React.useState<number>();
  const [feedback, setFeedback] = React.useState<string>();
  const [platformRating, setPlatformRating] = React.useState<number>();
  const [isLoading, setIsLoading] = React.useState(false);

  async function submit() {
    if (confidenceAfter !== undefined && favouritePetIntensity !== undefined) {
      setIsLoading(true);
      const user = await Auth.currentAuthenticatedUser();

      // Fetch form fields and split them into tests
      const newConfidenceAfter = user.attributes.hasOwnProperty(
        "custom:confidence_after"
      )
        ? user.attributes["custom:confidence_after"].split(".")
        : new Array(NumberOfTests).fill("-");

      if (newConfidenceAfter.length < NumberOfTests) {
        newConfidenceAfter.concat(
          new Array(NumberOfTests - newConfidenceAfter.length).fill("-")
        );
      }

      const newFavPetIntensity = user.attributes.hasOwnProperty(
        "custom:fav_pet_intensity"
      )
        ? user.attributes["custom:fav_pet_intensity"].split(".")
        : new Array(NumberOfTests).fill("-");

      if (newFavPetIntensity.length < NumberOfTests) {
        newFavPetIntensity.concat(
          new Array(NumberOfTests - newConfidenceAfter.length).fill("-")
        );
      }

      // For feedback, we need a different separator, as users can use punctiation
      // We are using a "record separator" control character (\u241e)
      // https://en.wikipedia.org/wiki/Unicode_control_characters
      const newFeedback = user.attributes.hasOwnProperty("custom:feedback")
        ? user.attributes["custom:feedback"].split("\u241e")
        : new Array(NumberOfTests).fill("-");

      if (newFeedback.length < NumberOfTests) {
        newFeedback.concat(
          new Array(NumberOfTests - newConfidenceAfter.length).fill("-")
        );
      }

      // Update replies for the current test id
      newConfidenceAfter[testId - 1] = confidenceAfter;
      newFavPetIntensity[testId - 1] = favouritePetIntensity;
      newFeedback[testId - 1] = feedback || "";

      await Auth.updateUserAttributes(user, {
        "custom:confidence_after": newConfidenceAfter.join("."),
        "custom:fav_pet_intensity": newFavPetIntensity.join("."),
        "custom:feedback": newFeedback.join("\u241e"),
        "custom:platform_rating": platformRating?.toString() || "0",
      });
      setIsLoading(false);
      onSubmit();
    }
  }

  return (
    <Card className="w-100">
      <Card.Body>
        <Card.Text>
          We would really appreciate if you could answer a couple of questions
          and share your feedback.
        </Card.Text>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>
              Confidence in assessing visually changes in PSMA-positive total
              tumor burden using PET images (after the training)
            </Form.Label>
            <div className="mb-3">
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <Form.Check
                  inline
                  key={i}
                  label={i}
                  type="radio"
                  name="confidenceAfter"
                  onClick={() => {
                    setConfidenceAfter(i);
                  }}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Which PET intensity was the most useful?</Form.Label>
            <div className="mb-3">
              {[5, 10, 15, 20].map((i) => (
                <Form.Check
                  inline
                  key={i}
                  label={i}
                  type="radio"
                  name="favouritePetIntensity"
                  onClick={() => {
                    setFavouritePetIntensity(i);
                  }}
                />
              ))}
            </div>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Feedback</Form.Label>
            <Form.Control
              as="textarea"
              maxLength={400}
              rows={3}
              value={feedback}
              onChange={(e) => {
                setFeedback(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>
              How would you rate the educational platform?
            </Form.Label>
            <div className="mb-3">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <Form.Check
                  inline
                  key={i}
                  label={i}
                  type="radio"
                  name="platformRating"
                  onClick={() => {
                    setPlatformRating(i);
                  }}
                />
              ))}
            </div>
          </Form.Group>
          <Button
            disabled={
              isLoading ||
              confidenceAfter === undefined ||
              favouritePetIntensity === undefined
            }
            variant="primary"
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              submit();
            }}
          >
            {isLoading && (
              <Spinner
                as="span"
                className="me-2"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            )}
            Submit
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

import { Col, Container, Row, Image, Button } from "react-bootstrap";
import JumbotronImage from "../assets/Jumbotron-image.png";
import useEducationalPlatformClick from "./useEducationalPlatformClick";

export default function Jumbotron() {
  const onEducationalPlatformClick = useEducationalPlatformClick();

  return (
    <Container fluid className="background-dark">
      <Container fluid="lg">
        <Row
          className="align-items-center text-white flex-md-nowrap"
          style={{ flexShrink: 0 }}
        >
          <Col xl="6" lg="7" md="8">
            <h1 className="mt-5">
              Response Evaluation Criteria <br className="d-xl-none" /> in
              PSMA&#8209;imaging (RECIP)
            </h1>
            <h4 className="mt-3">
              Novel evidence-based framework for response evaluation in
              metastatic prostate cancer using PSMA PET imaging
            </h4>
            <div>
              <Button
                size="lg"
                className="mt-3 mb-5 mx-auto btn-yellow"
                onClick={onEducationalPlatformClick}
              >
                Explore the educational platform
              </Button>
            </div>
          </Col>

          <Col
            lg="6"
            md="5"
            className="offset-xl-0 offset-lg-neg-1 offset-md-neg-1 d-none d-md-block"
            style={{
              overflow: "visible",
              width: "40rem",
            }}
          >
            <Image fluid src={JumbotronImage} alt="RECIP Illustration" />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Figure, Spinner } from "react-bootstrap";
import { Radiotracer } from "../testConfiguration";

import "./TestFigure.css";

type TestFigureProps = {
  src: string;
  caption: string;
  radiotracer: Radiotracer;
};

function TestFigure({
  src,
  caption,
  radiotracer,
}: TestFigureProps): React.ReactElement {
  const figureRef = useRef<HTMLImageElement>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
  }, [src]);

  // Handle the initial page load
  useEffect(() => {
    if (figureRef.current?.complete) {
      setLoading(false);
    }
  }, []);

  return (
    <Figure style={{ position: "relative" }}>
      {loading && (
        <Spinner
          animation="border"
          role="status"
          style={{
            zIndex: 10,
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      <Figure.Caption className="text-center">{radiotracer}</Figure.Caption>
      <Figure.Image
        ref={figureRef}
        onLoad={() => {
          setLoading(false);
        }}
        style={{
          opacity: loading ? 0 : 1,
          transition: "opacity 0.25s ease-in-out",
        }}
        className="TestFigure"
        src={src}
      />
      <Figure.Caption className="text-center">{caption}</Figure.Caption>
    </Figure>
  );
}

export default TestFigure;

import React from "react";
import { Col, Container, Row, Image } from "react-bootstrap";

interface NewsroomArticle {
  id: number;
  title: string;
  thumbnailURL: string;
}

export default function Newsroom(): React.ReactElement {
  const [articles, setArticles] = React.useState<NewsroomArticle[]>([]);

  // Fetch the articles on first render
  React.useEffect(() => {
    fetchArticles();
  }, []);

  async function fetchArticles() {
    const response = await fetch(
      "https://newsroom.recip-criteria.com/c/news/4.json",
      {
        mode: "cors",
      }
    );

    const jsonResponse = await response.json();

    if (!response.ok) {
      console.warn(
        `Failed to fetch the news: ${response.status} - ${jsonResponse}`
      );
    }

    const rawArticles = jsonResponse.topic_list.topics.filter(
      (topic: any) => topic.visible === true
    );

    setArticles(
      rawArticles.slice(0, 4).map((article: any) => ({
        id: article.id,
        title: article.title,
        thumbnailURL: article.image_url,
      }))
    );
  }

  return (
    <Container
      fluid
      className="background-light"
      style={{
        marginTop: "10rem",
      }}
    >
      <Container
        fluid="md"
        style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
      >
        <Row className="d-flex align-items-center">
          <Col>
            <h1
              className="text-center display-6 mb-5"
              style={{ fontWeight: 900 }}
            >
              [Adimin-only] Newsroom
            </h1>
            <h2 className="text-center mb-5">Featured articles</h2>
          </Col>
        </Row>
        <Row>
          {articles.map((article) => (
            <Col lg="3" xs="12" sm="6" key={`article-${article.id}`}>
              <a
                href={`https://newsroom.recip-criteria.com/t/${article.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "inherit",
                }}
              >
                <div className="ratio ratio-4x3">
                  <Image
                    rounded
                    fluid
                    src={article.thumbnailURL}
                    style={{ objectFit: "cover" }}
                  />
                </div>
                <div style={{ padding: 20 }}>
                  <h5 style={{ fontWeight: 500 }}>{article.title}</h5>
                </div>
              </a>
            </Col>
          ))}
        </Row>
        <Row className="d-flex align-items-center justify-content-center">
          <Col className="text-center">
            <a
              href="https://newsroom.recip-criteria.com/c/news/4"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-lg mt-5 mx-auto btn-outline-blue"
            >
              More articles
            </a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

import { Carousel, Col, Container, Image, Row, Table } from "react-bootstrap";

/* Images */
import aPROMISEImage2 from "../assets/aPROMISE-2.png";
import aPROMISEImage3 from "../assets/aPROMISE-3.png";
import aPROMISESlide1 from "../assets/Slide-1.png";
import aPROMISESlide2 from "../assets/Slide-2.png";
import aPROMISESlide3 from "../assets/Slide-3.png";
import aPROMISESlide4 from "../assets/Slide-4.png";

import aPROMISEVideo from "../assets/video.mp4";

export default function TechnologyPage() {
  return (
    <>
      <Container fluid className="background-dark text-white">
        <Container fluid="md">
          <h1
            className="display-3 text-center"
            style={{
              paddingTop: "10rem",
              paddingBottom: "10rem",
              fontWeight: 900,
            }}
          >
            Technology
          </h1>
        </Container>
      </Container>
      <Container
        fluid="md"
        style={{
          marginTop: "7.5rem",
        }}
      >
        {/* aPROMISE platform desctiption */}
        <h1
          className="text-center display-6"
          style={{
            fontWeight: 900,
            marginBottom: "2rem",
          }}
        >
          aPROMISE Platform
        </h1>
        <Row>
          <Col>
            <p style={{ textAlign: "justify" }}>
              aPROMISE provides accurate quantitative imaging biomarkers of
              clinical value, increasing reproducibility in the assessment of
              disease. aPROMISE enables a standardized, streamlined approach to
              determine patient eligibility for treatment with radioligand
              therapy, offering automated and accurate calculation of whole body
              SUVmean.
            </p>
            <p style={{ textAlign: "justify", marginBottom: 0 }}>
              AI-Based Detection and Adaptive Segmentation of Hotspots:
            </p>
            <ul>
              <li>
                Deep-learning technology, aPROMISE produces total-body PSMA
                detection and adaptive segmentation of lesions with a single
                click.
              </li>
              <li>
                Produces total-body quantification in both auto-segmented and
                manually selected lesions.
              </li>
              <li>
                Automated PSMA quantification at lesion level, including
                anatomical location and tissue type - tumor (miT), lymph node
                (miN, miMa), and visceral disease.
              </li>
            </ul>
            
            <div style={{ height: 'auto', maxWidth: '100%' }}>
            <video autoPlay muted loop style={{ maxHeight: '500px', width: '100%' }}>
            <source src={aPROMISEVideo} type="video/mp4" />
            </video>
            </div>
            
            <p style={{ marginTop: "1rem" }}>
              Total-body disease quantification is automated, allowing for
              considerable time-savings compared to manual calculation,
              particularly in patients with high-burden metastatic disease.
            </p>
            <h6>References:</h6>
            <small>
              <ol>
                <li>
                  FDA clearance letter for aPROMISE X. Food and Drug
                  Administration. April 29, 2022.&nbsp;
                  <a
                    href="https://www.accessdata.fda.gov/cdrh_docs/pdf22/K220590.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                </li>
                <li>
                  Nickols N, Anand A, Johnsson K, et al. aPROMISE: A Novel
                  Automated PROMISE Platform to Standardize Evaluation of Tumor
                  Burden in
                  <sup>18</sup>F-DCFPyL Images of Veterans with Prostate Cancer.
                  J Nucl Med. 2022;63(2):233-239.
                  doi:10.2967/jnumed.120.261863.&nbsp;
                  <a
                    href="https://pubmed.ncbi.nlm.nih.gov/34049980/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read article
                  </a>
                </li>
                <li>
                  Johnsson K, Brynolfsson J, Sahlstedt H, et al. Analytical
                  performance of aPROMISE: automated anatomic contextualization,
                  detection, and quantification of [<sup>18</sup>F]DCFPyL (PSMA)
                  imaging for standardized reporting. Eur J Nucl Med Mol
                  Imaging. 2022;49(3):1041-1051.
                  doi:10.1007/s00259-021-05497-8.&nbsp;
                </li>
                <a
                  href="https://pubmed.ncbi.nlm.nih.gov/34463809/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read article
                </a>
              </ol>
            </small>
          </Col>
        </Row>
      </Container>
      <Container fluid="md" style={{ marginTop: "7.5rem" }}>
        {/* Updated Automated PROMISE Assessment */}
        <h1
          className="text-center display-6"
          style={{
            fontWeight: 900,
          }}
        >
          Updated Automated PROMISE Assessment
        </h1>
        <h4
          className="text-center"
          style={{
            marginBottom: "2rem",
          }}
        >
          Treatment Response Evaluation Approach on Metastatic Prostate Cancer
          Patients Based on PSMA PET/CT
        </h4>
        <Row>
          <Col
            sm="5"
            className="d-flex align-items-center justify-content-center"
          >
            <Image
              fluid
              src={aPROMISEImage2}
              alt="Updated Automated PROMISE Assessment: Treatment Response
              Evaluation Approach on Metastatic Prostate Cancer Patients Based
              on PSMA PET/CT"
            />
          </Col>
          <Col sm="7">
            <p>
              <b>RESULTS:</b> Automated RECIP Classification (aRECIP) Dataset
              with 33 patients with prostate cancer who had a baseline and
              follow up [<sup>18</sup>F]DCFPyL PET/CT study after treatment.
            </p>
            <p className="mb-0">
              They were manually categorized as belonging to RECIP classes:
            </p>
            <Table borderless>
              <tbody>
                <tr>
                  <td>PD - Progressive disease:</td>
                  <td>4 patients</td>
                </tr>
                <tr>
                  <td>PR - Partial remission:</td>
                  <td>8 patients</td>
                </tr>
                <tr>
                  <td>CR - Complete remission:</td>
                  <td>9 patients</td>
                </tr>
                <tr>
                  <td>SD - Stable disease:</td>
                  <td>12 patients</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col>
            <h6 className="text-center">
              All 33 cases were automatically given the same class as they had
              been given manually.
            </h6>
            <h6 className="mb-0">Conclusion:</h6>
            <ul>
              <li>
                The AI enabled automated platform can efficiently, consistently,
                and accurately implement PSMA consensus guidelines.
              </li>
              <li>
                Implementation of the guidelines in clinical routine setting is
                essential for the clinical utility of PSMA PET-CT
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <Container fluid="md" style={{ marginTop: "7.5rem" }}>
        {/* ASCO GU 2024 */}
        <h1
          className="text-center display-6"
          style={{
            fontWeight: 900,
          }}
        >
          ASCO GU 2024
        </h1>
        <h4
          className="text-center"
          style={{
            marginBottom: "2rem",
          }}
        >
          Treatment response assessment per updated PROMISE criteria in patients
          with metastatic prostate cancer using an automated imaging platform
          for disease identification, measurement, and temporal tracking
        </h4>
        <Row>
          <Col>
            <Image
              fluid
              src={aPROMISEImage3}
              alt=" ASCO GU 2024: Treatment response assessment per updated PROMISE
              criteria in patients with metastatic prostate cancer using an
              automated imaging platform for disease identification, measurement,
              and temporal tracking."
              className="d-block mx-auto"
              style={{ maxHeight: "40rem" }}
            />
            <h6 className="text-center">
              In a cohort of 67 patients with metastatic prostate cancer,
              automated RECIP (aRECIP) evaluated automatically by AI-based
              aPROMISE platform was in agreement with physician-based evaluation
              of RECIP 1.0 in 93% of cases.
            </h6>
            <p>
              <b>Reference:</b>&nbsp;
              <small>
                Benitez CM, Sahlstedt H, Brynolfsson J, et al. Updated automated
                PROMISE assessment: Treatment response evaluation based on PSMA
                PET/CT. Journal of Clinical Oncology. 2024;42:48-48.&nbsp;
                <a
                  href="https://ascopubs.org/doi/10.1200/JCO.2024.42.4_suppl.48"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Read article
                </a>
              </small>
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid="md" style={{ marginTop: "7.5rem" }}>
        {/* Carousel */}
        <h1
          className="text-center display-6"
          style={{
            marginBottom: "2rem",
            fontWeight: 900,
          }}
        >
          Case Examples of aRECIP
        </h1>
        <Row>
          <Col>
            <Carousel variant="dark" data-bs-theme="dark">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{ height: "35rem", objectFit: "contain" }}
                  src={aPROMISESlide1}
                  alt="aRECIP case 1"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{ height: "35rem", objectFit: "contain" }}
                  src={aPROMISESlide2}
                  alt="aRECIP case 2"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{ height: "35rem", objectFit: "contain" }}
                  src={aPROMISESlide3}
                  alt="aRECIP case 3"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{ height: "35rem", objectFit: "contain" }}
                  src={aPROMISESlide4}
                  alt="aRECIP case 4"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
        <h5 className="mt-5">Additional info from aPROMISE AI platform.</h5>
        <Row style={{ marginBottom: "10rem" }}>
          <Col>
            <p>
              The AI enabled automated platform for RECIP classification also
              provides additional information about disease progression like
              exact total tumor volume (TTV) change, number of new lesions,
              number of lesions with complete remission and the miTNM types of
              lesions in baseline and follow up images respectively.{" "}
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}

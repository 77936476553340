import { Navigate, Outlet } from "react-router-dom";
import useAuthRoles from "./useAuthRoles";
import { Spinner } from "react-bootstrap";

interface RequireRoleProps {
  children?: React.ReactElement;
}

export default function RequireRole({ children }: RequireRoleProps) {
  const { isAdmin } = useAuthRoles();

  if (isAdmin == null) {
    return <Spinner animation="border" role="status" aria-hidden="true" />;
  }

  return isAdmin ? children || <Outlet /> : <Navigate to="/" />;
}

import { Amplify } from "aws-amplify";

export const AWSConfig = {
  region: "us-east-1",
  userPoolId: "us-east-1_eCXN7xZXM",
  userPoolWebClientId: "2sor8g5ofa3v1s7oabvaqds89r",
  identityPoolId: "us-east-1:8f51a347-1e9f-4955-a339-d550f8ee900a",
};

Amplify.configure({
  Auth: AWSConfig,
});

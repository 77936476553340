import { Auth } from "aws-amplify";
import React from "react";
import useAuth from "./useAuth";

export default function useAuthRoles() {
  const [loading, setLoading] = React.useState(true);
  const [roles, setRoles] = React.useState<Array<string>>([]);
  const { isAuthenticated } = useAuth();

  React.useEffect(() => {
    if (isAuthenticated) {
      fetchUserGroupParticipation();
    } else {
      setRoles([]);
    }
  }, [isAuthenticated]);

  async function fetchUserGroupParticipation() {
    const user = await Auth.currentAuthenticatedUser();
    const newRoles =
      user.signInUserSession.accessToken.payload["cognito:groups"];
    if (newRoles != null) {
      setRoles(newRoles);
    }
    setLoading(false);
  }

  const isAdmin = React.useMemo(() => {
    if (loading) {
      return undefined;
    }
    return roles.includes("admins");
  }, [loading, roles]);

  return { isAdmin };
}

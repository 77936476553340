import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "./useAuth";

interface RequireAuthProps {
  children?: React.ReactElement;
}

export default function RequireAuth({ children }: RequireAuthProps) {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  return isAuthenticated ? (
    children || <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

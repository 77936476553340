import { Container, Image } from "react-bootstrap";

import RadiologyLogo from "../assets/Radiology-logo.png";

export default function RadiologyFooter() {
  return (
    <Container fluid className="bg-off-black">
      <Container fluid="md">
        <div
          className="d-flex align-items-center justify-content-center flex-nowrap gap-3 py-2"
          style={{ marginRight: -12, marginLeft: -12 }}
        >
          <Image
            fluid
            style={{ height: 40, minWidth: 160, objectFit: "contain" }}
            src={RadiologyLogo}
            alt="Radiology Journal logo"
          />
          <div className="vr mx-2"></div>
          <div>
            <small className="text-off-black">
              Gafita A, Djaileb L, Rauscher I, et al. Response Evaluation
              Criteria in PSMA PET/CT (RECIP 1.0) in Metastatic
              Castration-resistant Prostate Cancer.
            </small>
            <br />
            <small className="text-off-black">
              Radiology. 2023. DOI: 10.1148/radiol.222148.{" "}
              <a
                href="https://pubs.rsna.org/doi/10.1148/radiol.222148"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read article
              </a>
            </small>
          </div>
        </div>
      </Container>
    </Container>
  );
}

import { useAuthenticator } from "@aws-amplify/ui-react";
import React from "react";

export default function useAuth() {
  const { route } = useAuthenticator((context) => [context.route]);

  const isAuthenticated = React.useMemo(() => {
    return route === "authenticated";
  }, [route]);

  return { isAuthenticated };
}

import { Col, Row } from "react-bootstrap";
import { Link, redirect, useNavigate } from "react-router-dom";
import {
  PatientIDs,
  ResponseCategory,
  TestPatientAssignment,
  TestResponses,
} from "../testConfiguration";
import { Auth } from "aws-amplify";
import React from "react";
import PostStudyQuestionnaire from "./PostStudyQuestionnaire";

type TestResultsProps = {
  testId: number;
  currentTestAnswers: Array<ResponseCategory | undefined>;
};

function TestResults({
  testId,
  currentTestAnswers,
}: TestResultsProps): React.ReactElement {
  const [
    shouldShowPostStudyQuestionnaire,
    setShouldShowPostStudyQuestionnaire,
  ] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    fetchUserAttributes();
  }, []);

  async function fetchUserAttributes() {
    const user = await Auth.currentAuthenticatedUser();
    setShouldShowPostStudyQuestionnaire(
      user.attributes.hasOwnProperty("custom:study_participant")
    );
  }

  const testPatients = TestPatientAssignment.get(testId);
  if (!testPatients) {
    console.error("Test definition not found");
    redirect("/");
    return <></>;
  }

  let correctAnswers = 0;
  currentTestAnswers.forEach((answer, i) => {
    const patientIndex = testPatients[i];
    const patientId = PatientIDs[patientIndex];
    const correctAnswer = TestResponses.get(patientId);
    if (answer === correctAnswer?.category) {
      correctAnswers += 1;
    }
  });

  return (
    <>
      <Row className="d-flex justify-content-bottom text-white">
        {/* Header */}
        <Col sm="3" className="mb-2 mt-auto">
          <h5 className="text-center text-sm-start">Test {testId}</h5>
        </Col>
        <Col sm="6" className="my-2">
          <h4 className="text-center">Results</h4>
        </Col>
      </Row>
      <Row className="mt-1 text-white">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <p className="mb-2">
            Congratulations! You have completed test {testId}!
          </p>
          <p>
            You have correctly answered {correctAnswers} out of{" "}
            {testPatients.length} questions.
          </p>
        </Col>
      </Row>
      {shouldShowPostStudyQuestionnaire && (
        <Row className="mt-3">
          <Col>
            <PostStudyQuestionnaire
              testId={testId}
              onSubmit={() => {
                navigate("/tests");
              }}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col className="d-flex py-3 justify-content-center">
          <Link to="/tests" className="btn btn-link text-white">
            Try more tests
          </Link>
        </Col>
      </Row>
    </>
  );
}

export default TestResults;

import RadiologyLogo from "../assets/Radiology-logo.png";
import JNMLogo from "../assets/JNM-logo.png";
import EJNMMILogo from "../assets/EJNMMI-logo.png";
import Publication, { JournalImage, PublicationProps } from "./Publication";

export default function Publications(): React.ReactElement {
  const radiology: JournalImage = {
    src: RadiologyLogo,
    alt: "Radiology Journal logo",
  };
  const jnm: JournalImage = {
    src: JNMLogo,
    alt: "Journal of Nuclear Medicine logo",
  };
  const ejinm: JournalImage = {
    src: EJNMMILogo,
    alt: "European Journal Nuclear Medicine and Molecular Imaging logo",
  };

  const publications: Array<PublicationProps> = [
    {
      journalImg: radiology,
      title:
        "Gafita A, Djaileb L, Rauscher I, et al. Response Evaluation Criteria in PSMA PET/CT (RECIP 1.0) in Metastatic Castration-resistant Prostate Cancer.",
      source: "Radiology. 2023. DOI: 10.1148/radiol.222148.",
      url: "https://pubs.rsna.org/doi/10.1148/radiol.222148",
    },
    {
      journalImg: jnm,
      title:
        "Gafita A, Rauscher I, Weber M, et al. Novel framework for treatment response evaluation using PSMA PET/CT in patients with metastatic castration-resistantprostate cancer (RECIP 1.0): an international multicenter study.",
      source:
        "Journal of Nuclear Medicine. 2022. DOI: 10.2967/jnumed.121.263072.",
      url: "https://jnm.snmjournals.org/content/63/11/1651",
    },
    {
      journalImg: ejinm,
      title:
        "Gafita A, Rauscher I, Fender WP, et al. Measuring response in metastatic castration-resistant prostate cancer using PSMA PET/CT: comparison of RECIST 1.1, aPCWG3, aPERCIST, PPP, and RECIP 1.0 criteria.",
      source:
        "European Journal Nuclear Medicine and Molecular Imaging. 2022. DOI: 10.1007/s00259-022-05882-x.",
      url: "https://link.springer.com/article/10.1007/s00259-022-05882-x",
    },
    {
      journalImg: jnm,
      title:
        "Shagera QA, Karfis I, Kristanto P, et al. PSMA PET/CT for Response Assessment and Overall Survival Prediction in Patients with Metastatic Castration-Resistant Prostate Cancer Treated with Androgen Receptor Pathway Inhibitors.",
      source:
        "Journal of Nuclear Medicine. 2023. DOI: 10.2967/jnumed.123.265874.",
      url: "https://pubmed.ncbi.nlm.nih.gov/37770114/",
    },
    {
      journalImg: ejinm,
      title:
        "Kendrick J, Francis RJ, Hassan GM, et al. Prognostic utility of RECIP 1.0 with manual and AI-based 68 segmentations in biochemically recurrent prostate cancer from [68Ga]Ga-PSMA-11 PET images.",
      source:
        "European Journal Nuclear Medicine and Molecular Imaging. 2023. DOI: 10.1007/s00259-023-06382-2.",
      url: "https://pubmed.ncbi.nlm.nih.gov/37550494/",
    },
    {
      journalImg: jnm,
      title:
        "Hartrampf PE, Hüttmann T, Seitz AK, et al. Prognostic Performance of RECIP 1.0 Based on [18F]PSMA-1007 PET in Prostate Cancer Patients Treated with [177Lu]Lu-PSMA I&T.",
      source:
        "Journal of Nuclear Medicine. 2024. DOI: 10.2967/jnumed.123.266702.",
      url: "https://pubmed.ncbi.nlm.nih.gov/38453363/",
    },
    {
      journalImg: jnm,
      title:
        "Kind F, Eder AC, Jilg CA, et al. Prognostic Value of Tumor Volume Assessment on PSMA PET After 177Lu-PSMA Radioligand Therapy Evaluated by PSMA PET/CT Consensus Statement and RECIP 1.0.",
      source:
        "Journal of Nuclear Medicine. 2023. DOI: 10.2967/jnumed.122.264489.",
      url: "https://pubmed.ncbi.nlm.nih.gov/36302658/",
    },
    {
      journalImg: jnm,
      title:
        "Murthy V, Gafita A, Thin P, et al. Prognostic Value of End-of-Treatment PSMA PET/CT in Patients Treated with 177Lu-PSMA Radioligand Therapy: A Retrospective, Single-Center Analysis.",
      source:
        "Journal of Nuclear Medicine. 2023. DOI: 10.2967/jnumed.122.265155.",
      url: "https://pubmed.ncbi.nlm.nih.gov/37678927/",
    },
  ];

  return (
    <>
      {publications.map((publication, index) => (
        <Publication {...publication} key={index} />
      ))}
    </>
  );
}

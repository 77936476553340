import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  PatientIDs,
  TestPatientAssignment,
  questionsPerTest,
} from "../testConfiguration";
import StudyQuestionnaire from "../components/StudyQuestionnaire";
import { Auth } from "aws-amplify";
import { useTestAnswersContext } from "../components/TestAnswersContext";

import RadiologyFooter from "../components/RadiologyFooter";

// Generate preload links - first set of images for each test
const preloadLinks: React.ReactElement[] = [];
TestPatientAssignment.forEach((patients) => {
  const firstPatientId = PatientIDs[patients[0]];
  const paddedFirstPatientId = firstPatientId.toString().padStart(3, "0");
  ["Before", "After"].forEach((time) => {
    preloadLinks.push(
      <link
        key={`${paddedFirstPatientId}-${time}`}
        rel="preload"
        href={`${process.env.PUBLIC_URL}/img/Patient${paddedFirstPatientId}-${time}-10.PNG`}
        as="image"
      />
    );
  });
});

function EducationalPlatformPage() {
  const [showStudyQuestionnaire, setShowStudyQuestionnaire] =
    React.useState(false);

  const { answers, correctAnswersForTestId } = useTestAnswersContext();

  const testCompletion = React.useMemo(() => {
    return Array.from(TestPatientAssignment.values()).map((patientIndices) => {
      for (let patientIndex of patientIndices) {
        if (answers[patientIndex] === undefined) {
          return false;
        }
      }
      return true;
    });
  }, [answers]);

  React.useEffect(() => {
    fetchUserAttributes();
  }, []);

  async function fetchUserAttributes() {
    const user = await Auth.currentAuthenticatedUser();

    const showStudyQuestionnaireCondition =
      user.attributes.hasOwnProperty("custom:study_participant") &&
      !user.attributes.hasOwnProperty("custom:country");
    setShowStudyQuestionnaire(showStudyQuestionnaireCondition);
  }

  return (
    <>
      {/* Preload links and modals */}
      {preloadLinks}
      <StudyQuestionnaire
        show={showStudyQuestionnaire}
        onHide={() => {
          setShowStudyQuestionnaire(false);
        }}
      />
      {/* Jumbotron */}
      <Container fluid className="text-white background-dark">
        <Container
          fluid="md"
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        >
          <Row className="d-flex align-items-center">
            <Col>
              <h1
                className="text-center display-3 mb-5"
                style={{ fontWeight: 900 }}
              >
                Educational Platform
              </h1>
              <p className="text-center">
                This platform was designed to offer free training to nuclear
                medicine physicians across the world who intend to use RECIP in
                their daily practice or in clinical trials. The goal of the
                training is to increase physicians' confidence in using
                Practical RECIP for treatment response evaluation and ensure
                delivery of the highest quality health care for men with
                prostate cancer.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid="lg" style={{ minHeight: "40vh" }}>
        <h1
          className="display-6 text-center"
          style={{ fontWeight: 900, marginTop: "5rem", marginBottom: "2rem" }}
        >
          Select one of the tests below to start
        </h1>
        <Row>
          <Col
            className="d-flex flex-wrap justify-content-center px-1 py-1"
            style={{ marginTop: "3rem" }}
          >
            {Array.from(TestPatientAssignment.keys()).map((testId) => (
              <div className="mx-2 my-1" style={{ width: "12rem" }}>
                <Link
                  to={`/tests/${testId}`}
                  key={`link-tests-${testId}`}
                  className="mb-2"
                >
                  <Button
                    size="lg"
                    className="w-100 btn-outline-blue"
                    style={{
                      fontSize: "1.75rem",
                      fontWeight: 800,
                      height: "4.5rem",
                      borderRadius: "0.625rem",
                      boxShadow: "0px 4px 60px 0px rgba(0, 0, 0, 0.10)",
                    }}
                  >
                    Test {testId}
                  </Button>
                </Link>
                <div className="mt-2 text-center">
                  {testCompletion[testId - 1] ? (
                    <span className="text-muted">
                      Your latest result is {correctAnswersForTestId(testId)}/
                      {questionsPerTest(testId)}{" "}
                    </span>
                  ) : (
                    <span style={{ color: "#1851A6" }}>New!</span>
                  )}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
      <RadiologyFooter />
    </>
  );
}

export default EducationalPlatformPage;

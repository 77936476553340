import { Button, Col, Container, Row, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRECIPOnlineContext } from "../components/RECIPOnlineContext";
import RECIPOnlineWaiver from "../components/RECIPOnlineWaiver";
import RECIPOnlineHeader from "../assets/RECIPOnline-header.png";

export default function RECIPOnlineStudyPage() {
  const navigate = useNavigate();

  const { setStudyParticipant, setConsentGiven } = useRECIPOnlineContext();

  function onContinue() {
    setStudyParticipant(true);
    setConsentGiven(true);
    navigate("/signup");
  }

  return (
    <>
      <Container
        fluid
        className="background-dark
      7 "
      >
        <Container fluid="lg">
          <Row
            className="align-items-center text-white flex-md-nowrap"
            style={{ flexShrink: 0 }}
          >
            <Col md="7" lg="6">
              <h1 className="mt-5">RECIPonline Study</h1>
              <h4 className="mt-3">
                If you agree with the information presented below in the waiver
                of documentation of consent, please
              </h4>
              <div>
                <Button
                  size="lg"
                  className="mt-3 mb-5 mx-auto btn-yellow"
                  onClick={onContinue}
                >
                  Create account and begin study
                </Button>
              </div>
            </Col>

            <Col lg="6" md="5" className="d-none d-md-block text-end">
              <Image
                fluid
                className="my-5"
                style={{
                  borderRadius: 10,
                  boxShadow:
                    "20px 0px 4px -10px rgba(255, 255, 255, 0.20), 40px 0px 4px -20px rgba(255, 255, 255, 0.15), 62px 0px 4px -35px rgba(255, 255, 255, 0.10)",
                  maxWidth: "30rem",
                }}
                src={RECIPOnlineHeader}
                alt="RECIPOnline study Illustration"
              />
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid style={{ maxWidth: 768 }}>
        <Row>
          <Col>
            <RECIPOnlineWaiver />
            <div className="w-100 d-flex align-items-center">
              <Button
                size="lg"
                variant="primary"
                className="mb-5 mt-5 mx-auto btn-blue"
                onClick={onContinue}
              >
                Create account and begin study
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

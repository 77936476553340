import {
  Button,
  Carousel,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";

import "./LandingPage.css";

import Jumbotron from "../components/Jumbotron";
import PartnerLogo from "../components/PartnerLogo";
import Publications from "../components/Publications";
import Newsroom from "../components/Newsroom";

/* Landing page assets */
import IntroImage1 from "../assets/RECIP Image Intro 1.png";
import IntroImage2 from "../assets/RECIP Image Intro 2.png";
import IntroImage3 from "../assets/RECIP Image Intro 3.png";

/* Logos */
import TUMLogo from "../assets/TUM-logo.svg";
import JHULogo from "../assets/JHU-logo.png";
import UDELogo from "../assets/UDE-logo.jpg";
import UCLALogo from "../assets/UCLA-logo.svg";
import UNIBOLogo from "../assets/UNIBO-logo.png";
import UGALogo from "../assets/UGA-logo.svg";
import PCFLogo from "../assets/PCF-logo.png";
import RecipCriteriaImage1 from "../assets/RECIP Criteria 1.0 1.png";
import RecipCriteriaImage2 from "../assets/RECIP Criteria 1.0 2.png";
import PromiseV2Image from "../assets/PROMISE V2.png";
import PracticalRecip from "../assets/PracticalRecip.png";
import aPROMISEScreenshot from "../assets/aPROMISE-Screenshot.png";
import useEducationalPlatformClick from "../components/useEducationalPlatformClick";
import useAuthRoles from "../components/useAuthRoles";
import { Link } from "react-router-dom";

function LandingPage() {
  const onEducationalPlatformClick = useEducationalPlatformClick();
  const { isAdmin } = useAuthRoles();

  return (
    <>
      <Jumbotron />
      <Container fluid="md">
        {/* Practical RECIP */}
        <Row
          className="d-flex align-items-center"
          style={{ marginTop: "7.5rem" }}
        >
          <Col>
            <p style={{ textAlign: "justify", fontSize: "1.75rem" }}>
              Response Evaluation Criteria In PSMA-PET/CT (RECIP) is a
              standardized evidence-based framework developed by an
              interdisciplinary team of investigators to evaluate efficacy of
              systemic therapies in metastatic castration-resistant prostate
              cancer.
            </p>
          </Col>
        </Row>
        <Row
          style={{ marginTop: "3.75rem" }}
          className="d-flex align-items-stretch"
        >
          <Col xl="4" lg="5">
            <div className="PracticalRECIP-image">
              <Image fluid src={PracticalRecip} />
            </div>
          </Col>
          <Col
            xl="8"
            lg="7"
            className="PracticalRECIP-content d-flex flex-column justify-content-between"
          >
            <p>
              RECIP combines <b>changes in total tumor volume</b> and{" "}
              <b>status of new lesions</b> and can be calculated dual-way:
              Visual RECIP and Quantitative RECIP.
            </p>
            <p>
              <b>
                <u>Visual RECIP:</u>
              </b>{" "}
              Changes in total volume are evaluated by visual reads of nuclear
              medicine physicians or radiologists, and are combined with status
              of new lesions to calculate RECIP.
            </p>
            <p>
              <b>
                <u>Quantitative RECIP:</u>
              </b>{" "}
              Changes in total tumor volume are evaluated quantitatively using
              tumor segmentation software and combined with status of new
              lesions to calculate RECIP.
            </p>
            <p>
              Visual RECIP and Quantitative RECIP showed{" "}
              <b>
                <u>95% agreement</u>
              </b>{" "}
              for identifying progression vs. non-progression.
            </p>
            <p>
              RECIP demonstrated{" "}
              <b>
                <u>excellent inter-reader agreement</u>
              </b>{" "}
              among 5 independent readers (complete agreement: 83% for visual
              RECIP and 92% for quantitative RECIP) which suggests that RECIP is
              ready for implementation in clinical practice.
            </p>
          </Col>
        </Row>
        {/* RECIP definition */}
        <Row
          className="d-flex align-items-center"
          style={{ marginTop: "8rem" }}
        >
          <Col>
            <h1
              className="text-center display-6 mb-5"
              style={{ fontWeight: 900 }}
            >
              RECIP 1.0 Definition
            </h1>
            <p style={{ marginBottom: 16 }}>
              RECIP integrates changes in total PSMA-positive tumor volume
              (PSMA-VOL) between baseline and follow-up scan and occurrence of
              new lesions.
            </p>
            <Table
              striped
              bordered
              hover
              className="align-middle m-0"
              style={{ borderRadius: "0.5rem", overflow: "hidden" }}
            >
              <tbody>
                <tr>
                  <td>Complete Response</td>
                  <td style={{ minWidth: 100 }}>
                    <b>RECIP-CR</b>
                  </td>
                  <td>Absense of any PSMA-update on follow-up PET scan</td>
                </tr>
                <tr>
                  <td>Partial Response</td>
                  <td>
                    <b>RECIP-PR</b>
                  </td>
                  <td>
                    &#8805;30% decrease in PSMA-VOL without appearance of new
                    lesions
                  </td>
                </tr>
                <tr>
                  <td>Progressive Disease</td>
                  <td>
                    <b>RECIP-PD</b>
                  </td>
                  <td>
                    &#8805;20% increase in PSMA-VOL with appearance of new
                    lesions
                  </td>
                </tr>
                <tr>
                  <td>Stable Disease</td>
                  <td>
                    <b>RECIP-SD</b>
                  </td>
                  <td>
                    &lt;30% decrease in PSMA-VOL with/without appearance of new
                    lesions <i>or</i>
                    <br />
                    &#8805;30% decrease in PSMA-VOL with appearance of new
                    lesions <i>or</i>
                    <br />
                    &lt;20% increase in PSMA-VOL with/without appearance of new
                    lesions <i>or</i>
                    <br />
                    &#8805;20% increase in PSMA-VOL without appearance of new
                    lesions
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        {/* RECIP criteria images */}
        <Row className="my-5">
          <Col xs="6" sm="4">
            <Image
              fluid
              src={IntroImage1}
              alt="RECIP-SD Illustration"
              className="d-block mx-auto"
              style={{ maxHeight: "24rem" }}
            />
          </Col>
          <Col xs="6" sm="4">
            <Image
              fluid
              src={IntroImage2}
              alt="RECIP-SD Illustration"
              className="d-block mx-auto"
              style={{ maxHeight: "24rem" }}
            />
          </Col>
          <Col xs={{ span: 6, offset: 3 }} sm={{ span: 4, offset: 0 }}>
            <Image
              fluid
              src={IntroImage3}
              alt="RECIP-PD Illustration"
              className="d-block mx-auto"
              style={{ maxHeight: "24rem" }}
            />
          </Col>
        </Row>
        {/* Partners */}
        <h1
          className="text-center display-6 mb-5"
          style={{ marginTop: "8rem", fontWeight: 900 }}
        >
          Developed by an international multi-disciplinary
          <br />
          collaborative group of investigators
        </h1>
        <Row className="align-items-center">
          <Col xl="2" md="4" xs="6" className="mb-3">
            <PartnerLogo
              src={TUMLogo}
              alt="Technical University of Munich"
              width="33%"
            />
          </Col>
          <Col xl="2" md="4" xs="6" className="mb-3">
            <PartnerLogo
              src={JHULogo}
              alt="Johns Hopkins University"
              width="70%"
            />
          </Col>
          <Col xl="2" md="4" xs="6" className="mb-3">
            <PartnerLogo
              src={UDELogo}
              alt="University of Duisburg-Essen"
              width="70%"
            />
          </Col>
          <Col xl="2" md="4" xs="6" className="mb-3">
            <PartnerLogo
              src={UCLALogo}
              alt="University of California Los Angeles"
              width="40%"
            />
          </Col>
          <Col xl="2" md="4" xs="6" className="mb-3">
            <PartnerLogo src={UNIBOLogo} alt="University of Bologna" />
          </Col>
          <Col xl="2" md="4" xs="6" className="mb-3">
            <PartnerLogo
              src={UGALogo}
              alt="Université Grenoble Alpes"
              width="40%"
            />
          </Col>
        </Row>
        {/* Supported by */}
        <Row className="mt-3">
          <Col className="d-flex justify-content-center align-items-center">
            <div
              className="d-flex py-2 px-3 justify-content-center align-items-center"
              style={{
                borderRadius: "1.875rem",
                border: "4px solid #1850A5",
              }}
            >
              <span
                className="me-2"
                style={{
                  fontWeight: 500,
                  fontSize: "1.25rem",
                  lineHeight: "1.5rem",
                }}
              >
                Supported by
              </span>
              <Image
                className="d-block"
                fluid
                style={{ maxHeight: "6rem" }}
                src={PCFLogo}
                alt="Prostate Cancer Foundation Young Investigator Award"
              />
            </div>
          </Col>
        </Row>
        {/* Carousel */}
        <h1
          className="text-center display-6 mb-5"
          style={{ marginTop: "8rem", fontWeight: 900 }}
        >
          Response Evaluation Criteria in PSMA-Imaging (RECIP) 1.0
        </h1>
        <Row>
          <Col>
            <Carousel variant="dark" data-bs-theme="dark">
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{ height: "35rem", objectFit: "contain" }}
                  src={RecipCriteriaImage1}
                  alt="RECIP 1.0"
                />
              </Carousel.Item>
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  style={{
                    height: "35rem",
                    objectFit: "contain",
                  }}
                  src={RecipCriteriaImage2}
                  alt="RECIP 1.0"
                />
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>
      {/* Educational Platform */}
      <Container
        fluid
        className="text-white background-dark"
        style={{
          marginTop: "10rem",
        }}
      >
        <Container
          fluid="md"
          style={{ paddingTop: "5rem", paddingBottom: "5rem" }}
        >
          <Row className="d-flex align-items-center">
            <Col>
              <h1
                className="text-center display-6 mb-5"
                style={{ fontWeight: 900 }}
              >
                Educational Platform
              </h1>
              <p style={{ textAlign: "justify" }}>
                Definition of original RECIP incorporated quantitative changes
                of PSMA-VOL calculated using semi-automatic software for
                whole-body tumor segmentation in PSMA PET. However, this process
                can still be time consuming with an average time of 10 minutes
                per scan. Hence, quantification of PSMA-VOL is not feasible yet
                for use in daily practice. Nevertheless, standardized treatment
                response evaluation in metastatic prostate cancer in clinical
                routine using next-generation PSMA imaging is an urgent need. In
                a recent study published in Radiology, RECIP investigators found
                that changes in PSMA-VOL determined visually by five
                board-certified nuclear medicine physicians had 95% correlation
                with quantitative changes in PSMA-VOL calculated using
                semi-automatic tumor segmentation software. These findings
                suggest that RECIP assessed by nuclear medicine physicians
                (Practical RECIP) can be used in daily practice.
              </p>
              <div className="text-center">
                <Button
                  size="lg"
                  className="mt-5 mx-auto btn-yellow"
                  onClick={onEducationalPlatformClick}
                >
                  Explore the educational platform
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid="md">
        {/* PROMISE V2 */}
        <h1
          className="text-center display-6 mb-5"
          style={{
            marginTop: "7.5rem",
            fontWeight: 900,
          }}
        >
          PSMA-PET/CT Standardized Reporting
        </h1>
        <Row className="d-flex align-items-center">
          <Col lg="6">
            <h2 className="text-center mb-5">PROMISE V2 framework</h2>
            <p style={{ textAlign: "justify", fontWeight: 700 }}>
              PSMA-PET is emerging to become a reference imaging tool for the
              staging and restaging of patients with prostate cancer for both
              clinical routine and trials.
            </p>
            <p style={{ textAlign: "justify", fontWeight: 700 }}>
              The prostate cancer molecular imaging standardized evaluation
              (PROMISE) criteria version 1 have been proposed as a comprehensive
              framework for whole-body staging to describe the prostate cancer
              disease extent on PSMA-PET.
            </p>
            <p style={{ textAlign: "justify", fontWeight: 700 }}>
              <a
                href="https://pubmed.ncbi.nlm.nih.gov/36935345/"
                target="_blank"
                rel="noopener noreferrer"
              >
                PROMISE version 2 (V2)
              </a>{" "}
              were recently published and provide harmonized TNM categories,
              improved assessment of local disease, and updated PSMA-expression
              score. A reporting template for response assessment in clinical
              trials by defining qualitative and quantitative imaging parameters
              to be recorded. The PROMISE V2 templates can serve as a basis for
              current and future response assessment frameworks.
            </p>
          </Col>
          <Col lg="6">
            <Image
              fluid
              src={PromiseV2Image}
              alt="Promise V2 Illustration"
              className="d-block mx-auto"
              style={{ maxHeight: "32rem" }}
            />
          </Col>
        </Row>
        <Row
          className="d-flex align-items-center"
          style={{ marginTop: "5rem" }}
        >
          <Col lg="6">
            <Image
              fluid
              src={aPROMISEScreenshot}
              alt="aPROMISE Illustration"
              className="d-block mx-auto"
              style={{ maxHeight: "32rem" }}
            />
          </Col>
          <Col lg="6">
            <h2 className="text-center mb-5">aPROMISE</h2>
            <p style={{ textAlign: "justify", fontWeight: 700 }}>
              aPROMISE is an AI-based software that provides accurate
              quantitative imaging biomarkers of clinical value, increasing
              reproducibility in the assessment of disease.
            </p>
            <p style={{ textAlign: "justify", fontWeight: 700 }}>
              aPROMISE have shown that AI-based automatic lesion detection and
              total tumor volume quantification using PSMA PET/CT imaging can be
              used to track treatment response and automatically calculate RECIP
              criteria (aRECIP) in patients with prostate cancer.
            </p>
            <p style={{ textAlign: "justify", fontWeight: 700 }}>
              The AI-based automated algorithm aRECIP achieved the same clinical
              classifications as manual lesions selection and classification of
              RECIP in 100% of cases
            </p>
            <Link to="/technology" style={{ fontWeight: 700 }}>
              Learn more about aRECIP technology
            </Link>
          </Col>
        </Row>
      </Container>
      {/* Newsroom */}
      {isAdmin && <Newsroom />}
      <Container fluid="md">
        <h1
          className="text-center display-6 mb-5"
          style={{ marginTop: "8rem", fontWeight: 900 }}
        >
          RECIP publications
        </h1>
        <Publications />
      </Container>
    </>
  );
}

export default LandingPage;

import React from "react";
import { Image } from "react-bootstrap";

export type PublicationProps = {
  journalImg: JournalImage;
  title: string;
  source: string;
  url: string;
};

export type JournalImage = {
  src: string;
  alt: string;
};

export default function Publication({
  journalImg,
  title,
  source,
  url,
}: PublicationProps): React.ReactElement {
  return (
    <div
      className="d-flex align-items-center flex-nowrap gap-3"
      style={{ marginRight: -12, marginLeft: -12, marginBottom: 16 }}
    >
      <Image
        fluid
        style={{ height: 40, minWidth: 160, objectFit: "contain" }}
        src={journalImg.src}
        alt={journalImg.alt}
      />
      <div className="vr mx-2"></div>
      <div>
        <small>{title}</small>
        <br />
        <small>
          {source}{" "}
          <a href={url} target="_blank" rel="noopener noreferrer">
            Read article
          </a>
        </small>
      </div>
    </div>
  );
}

import { Container } from "react-bootstrap";
import Publications from "../components/Publications";

export default function ScienceAndImpactPage() {
  return (
    <>
      <Container fluid className="background-dark text-white">
        <Container fluid="md">
          <h1
            className="display-3 text-center"
            style={{
              paddingTop: "10rem",
              paddingBottom: "10rem",
              fontWeight: 900,
            }}
          >
            Science &amp; Impact
          </h1>
        </Container>
      </Container>
      <Container fluid="md">
        <h1
          className="text-center display-6"
          style={{ marginTop: "7.5rem", marginBottom: "2rem", fontWeight: 900 }}
        >
          RECIP publications
        </h1>
        <Publications />
      </Container>
    </>
  );
}

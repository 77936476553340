import { Image, OverlayTrigger, Tooltip } from "react-bootstrap";

type PartnerLogoProps = {
  src: string;
  alt: string;
  width?: string;
};

function PartnerLogo({
  src,
  alt,
  width = "auto",
}: PartnerLogoProps): React.ReactElement {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip>{alt}</Tooltip>}>
      <Image
        className="py-2 mx-auto d-block"
        fluid
        style={{ maxWidth: width, maxHeight: "8rem" }}
        src={src}
        alt={alt}
      />
    </OverlayTrigger>
  );
}

export default PartnerLogo;

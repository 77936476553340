import { useNavigate } from "react-router-dom";
import { useRECIPOnlineContext } from "./RECIPOnlineContext";
import useAuth from "./useAuth";

export default function useEducationalPlatformClick() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { setShowFlyer } = useRECIPOnlineContext();

  function onEducationalPlatformClick() {
    if (isAuthenticated) {
      navigate("/tests");
    } else {
      setShowFlyer(true);
    }
  }

  return onEducationalPlatformClick;
}
